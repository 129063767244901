import styled from 'styled-components';

import { max, min } from '../../styles/breakpoints';

/* Optional background colors
 * background-color: #FFC940; (darker yellow)
 * background-color: #E6E095; (lighter yellow)
 */

export const Banner = styled.div`
  background: #FFC940;
  background: -webkit-linear-gradient(to right, #FFC137, #FFC940);
  background: linear-gradient(to right, #FFC137, #FFC940);
  padding: 50px 0;
  display: flex;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  width: 90vw;
  max-width: 1250px;

  @media (max-width: ${max.xs}) {
    width: 100%;
    padding: 0 25px;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &:first-child {
    padding-bottom: 20px;
    border-bottom: 1px solid darkorange;
  }

  &:last-child {
    padding-top: 35px;
  }
`;

export const Caption = styled.h3`
  margin: 0;
  font-size: 26px;
  font-family: poppins, sans-serif;
  font-weight: 400;
  text-align: center;
  line-height: 1.75;
  padding: 0 35px;
  margin-bottom: 15px;

  @media (min-width: ${min.md}) and (max-width: ${max.xl}) {
    font-size: 22px;
  }

  @media (min-width: ${min.xs}) and (max-width: ${max.md}) {
    font-size: 20px;
  }

  @media (max-width: ${max.xs}) {
    font-size: 18px;
    padding: 0 15px;
  }
`;
