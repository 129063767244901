import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Envelope from '../../blog/components/icons/envelope';
import GitHub from '../../blog/components/icons/github';
import Instagram from '../../blog/components/icons/instagram';
import LinkedIn from '../../blog/components/icons/linkedin';
import Twitter from '../../blog/components/icons/twitter';
import YouTube from '../../blog/components/icons/youtube';

import Button from '../../components/Button/Button';
import LinkWrapper from '../../components/LinkWrapper/LinkWrapper';

import {
  GreetingText,
  HeadingContainer,
  ImgContainer,
  JumboContainer,
  NameHeading,
  NavHeader,
  SocialContainer,
  SubHeading,
  SubheadingContainer,
} from './Jumbotron.styles';

const Jumbotron = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "assets/david-main.png" }) {
        childImageSharp {
          gatsbyImageData(width: 675, breakpoints: [ 360, 540, 600, 750, 850 ], sizes: "(max-width: 360px) 230px, (max-width: 550px) 300px, 100vw") 
        }
      }
    }
  `);

  return (
    <JumboContainer>
      <ImgContainer>
        <GatsbyImage
          image={data.file.childImageSharp.gatsbyImageData}
          alt="Photo of David Nowak"
          loading="eager"
        />
      </ImgContainer>
      <NavHeader />
      <HeadingContainer>
        <GreetingText>HEY THERE!</GreetingText>
        <NameHeading>I'm David Nowak</NameHeading>
      </HeadingContainer>
      <SubheadingContainer>
        <SubHeading>
          I successfully switched careers into tech by teaching myself how to code. Now I'm
          on a journey towards financial independence through programming and investing.
        </SubHeading>
        <SubHeading>PS - The journey hasn’t been easy, but it’s definitely possible!</SubHeading>
      </SubheadingContainer>
      <SocialContainer>
        <Button social ariaLabel='LinkedIn' size={32}>
          <LinkWrapper to='https://www.linkedin.com/in/davidpnowak' ariaLabel='LinkedIn'>
            <LinkedIn />
          </LinkWrapper>
        </Button>

        <Button social ariaLabel="Twitter" size={32}>
          <LinkWrapper to='https://twitter.com/davidpnowak' ariaLabel="Twitter">
            <Twitter size={1.6} />
          </LinkWrapper>
        </Button>

        <Button social ariaLabel="Instagram" size={32}>
          <LinkWrapper to='https://www.instagram.com/davidpnowak' ariaLabel="Instagram">
            <Instagram />
          </LinkWrapper>
        </Button>

        <Button social ariaLabel="GitHub" size={32}>
          <LinkWrapper to='https://github.com/Confidenceiskey' ariaLabel="GitHub">
            <GitHub size={1.45} />
          </LinkWrapper>
        </Button>

        <Button social ariaLabel="YouTube" size={32}>
          <LinkWrapper to='https://www.youtube.com/c/davidnowak' ariaLabel="YouTube">
            <YouTube size={1.17} />
          </LinkWrapper>
        </Button>

        <Button social ariaLabel="Email" size={32}>
          <LinkWrapper to='mailto:hello[at]davidpnowak.com' ariaLabel="Email">
            <Envelope size={1.39} />
          </LinkWrapper>
        </Button>
      </SocialContainer>
    </JumboContainer>
  );
};

export default Jumbotron;
