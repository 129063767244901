import React from 'react';

import NavigationInPage from '../../components/Navigation/NavigationInPage';
import SectionHeading from '../../components/SectionHeading/SectionHeading';

import { PortfolioContainer } from './Portfolio.styles';

const Portfolio = () => (
  <PortfolioContainer id="portfolio">
    <SectionHeading text="My Portfolio" shapeColor="rgba(255, 202, 66, 0.65)" />
    <NavigationInPage />
  </PortfolioContainer>
);

export default Portfolio;
