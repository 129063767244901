import React from 'react';
import Helmet from 'react-helmet';

import { config } from '../website-config';

const MetaData = ({
  author,
  description,
  height,
  image,
  imageAlt,
  location,
  page,
  published,
  robots,
  tags,
  title,
  type,
  updated,
  url,
  width,
}) => {
  const fullImageUrl = `${config.siteUrl}${image}`;
  const fullUrl = `${config.siteUrl}${url}`;
  const locationUrl = url ? fullUrl : `${location.url}${page > 1 ? `${page}/` : ''}`;
  const uniqueBlogTitle = location?.url.includes('blog') ? `${location.title}${page > 1 ? ` - page ${page}` : ''}` : title;

  return (
    <Helmet
      htmlAttributes={{
        lang: config.lang,
      }} 
    >
      <title>{uniqueBlogTitle || location.title}</title>
      <meta name="description" content={description || location.description} />

      {/* <!-- Google Tags --> */}
      <meta itemprop="name" content={uniqueBlogTitle || location.title} />
      <meta itemprop="description" content={description || location.description} />

      {/* config.googleSiteVerification && <meta name="google-site-verification" content={config.googleSiteVerification} /> */}

      {/* <!-- Social Tags --> */}
      <meta property="og:url" content={locationUrl} />
      <meta property="og:type" content={type || 'website'} />
      <meta property="og:title" content={uniqueBlogTitle || location.title} />
      <meta property="og:description" content={description || location.description} />

      {location || image ? (
        <meta itemprop="image" content={image ? fullImageUrl : location.imgUrl} />
      ) : null}
      {location || image ? (
        <meta property="og:image" content={image ? fullImageUrl : location.imgUrl} />
      ) : null}
      {location || width ? (
        <meta property="og:image:width" content={width || location.imgWidth} />
      ) : null}
      {location || height ? (
        <meta property="og:image:height" content={height || location.imgHeight} />
      ) : null}

      {imageAlt || location ? (
        <meta property="og:image:alt" content={imageAlt || location.imgAlt} />
      ) : null}
      {imageAlt || location ? (
        <meta name="twitter:image:alt" content={imageAlt || location.imgAlt} />
      ) : null}

      {author ? <meta property="article:author" content={author} /> : null}
      {author ? <meta name="twitter:label1" content="Written by" /> : null}
      {author ? <meta name="twitter:data1" content={author} /> : null}

      {published ? <meta property="article:published_time" content={published} /> : null}
      {updated ? (
        <meta property="article:modified_time" content={new Date(updated).toISOString()} />
      ) : null}
      {tags ? <meta property="article:tag" content={tags[0]} /> : null}

      {/* <!-- Extra Twitter Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@davidpnowak" />
      <meta name="twitter:creator" content="@davidpnowak" />

      <link rel="canonical" href={locationUrl} />
      {robots ? <meta name="robots" content={robots} /> : null}
      <script
        async
        defer
        data-domain="davidpnowak.com"
        src="https://analytics.pixeljab.com/js/plausible.js"
      ></script>
    </Helmet>
  );
};

export default MetaData;
