export const blogData = [
  {
    title: 'Investing',
    body: 'Curious to know what kind of investments even exist? Which ones are the best? And is it even possible to 10x your net worth in a year? Then check out all of my top investing articles...',
    stripColor: 'forestgreen',
    img: {
      alt: 'Stacks of money getting bigger to illustrate investing',
      loading: 'lazy',
    },
    url: '/tags/investing/',
  },
  {
    title: 'Programming',
    body: 'Are you considering switching careers into tech? Do you want a step-by-step guide of how to get a programming job? Want to learn how I broke into tech? Then check out my programming articles...',
    stripColor: 'red',
    img: {
      alt: 'Computer screen with HTML code to illustrate programming',
      loading: 'lazy',
    },
    url: '/tags/programming/',
  }
];
