import styled from 'styled-components';

import { min, max } from '../../styles/breakpoints';

export const PortfolioContainer = styled.section`
  background-color: #a6dff2;
  padding: 60px 0 90px;

  @media (min-width: ${min.xs}) and (max-width: ${max.lg}) {
    padding: 60px;
  }

  @media (min-width: ${min.lg}) {
    padding: 60px 120px;
  }
`;