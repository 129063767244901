import styled from 'styled-components';
import { Link } from 'gatsby';

import { max, min } from '../../../styles/breakpoints';

const headingHeight = 70;
const stripHeight = 24;

export const BlogCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  align-items: center;
  transition: transform 0.5s ease;

  &:first-child {
    margin-bottom: 80px;
  }

  &:hover {
    transform: scale(1.05);
    transition: transform 0.5s ease;
  }

  @media (max-width: ${max.xs}) {
    &:hover {
      transform: unset;
      transition: unset;
    }
  }

  @media (min-width: ${min.xl}) {
    &:first-child {
      margin-right: 50px;
      margin-bottom: unset;
    }
  }
`;

export const BlogCardImageContainer = styled(Link)`
  width: 100%;
  cursor: pointer;
`;

export const BlogCardCaption = styled.section`
  display: flex;
  font-family: Georgia, serif;
  flex-direction: column;
  z-index: 1;
  padding: 0 25px 15px;
  box-sizing: border-box;
  background-color: #fff;
  width: 90%;
  height: auto;
  margin-top: -${headingHeight}px;
  border-radius: 10px;
  box-shadow: -1px 3px 12px 6px rgba(69, 67, 96, 0.1);
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: unset;
`;

export const CaptionTitle = styled.header`
  display: flex;
  position: relative;
  align-items: center;
  margin: 0;
  background-color: #fff;
  width: 100%;
  flex-basis: ${headingHeight}px;
  border-radius: 10px 10px 0 0;
`;

export const Title = styled.h3`
  margin: 0;
  font-size: 1.8rem;
`;

export const TitleStrip = styled.div`
  position: absolute;
  background-color: ${({ stripColor }) => stripColor || 'red'};
  height: ${stripHeight}px;
  width: 5px;
  right: -25px;
  top: calc((${headingHeight}/2) - (${stripHeight}/2))px;
`;
export const CaptionBody = styled.p`
  margin: 0;
  background-color: #fff;
  font-size: 1rem;
  line-height: 1.5;
  width: 100%;
  box-sizing: border-box;
  flex-grow: 1;
  border-radius: 0 0 10px 10px;

  @media (min-width: 1000px) {
    line-height: 1.8;
  }
`;
