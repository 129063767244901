import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  BlogCardCaption,
  BlogCardContainer,
  BlogCardImageContainer,
  CaptionBody,
  CaptionTitle,
  StyledLink,
  Title,
  TitleStrip,
} from './BlogCard.styles';

const BlogCard = ({ body, imgData, linkUrl, stripColor, title }) => {
  const { alt, imgUrl, loading } = imgData;
  return (
    <BlogCardContainer>
      <BlogCardImageContainer to={linkUrl}>
        <GatsbyImage image={imgUrl} alt={alt} loading={loading} />
      </BlogCardImageContainer>
      <BlogCardCaption as={StyledLink} to={linkUrl}>
        <CaptionTitle>
          <Title>{title}</Title>
          <TitleStrip stripColor={stripColor} />
        </CaptionTitle>
        <CaptionBody>{body}</CaptionBody>
      </BlogCardCaption>
    </BlogCardContainer>
  );
};

export default BlogCard;
