import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { max, min } from '../../styles/breakpoints';

/*
 * BASE STYLES
 */

export const BaseUl = styled.ul`
  list-style-type: none;
  display: flex;
  margin: 0;
  align-items: center;

  @media (max-width: ${max.sm}) {
    padding-left: 0;
  }
`; 

export const BaseLi = styled.li`
  position: relative;
  margin: 0;
  display: flex;
`;

const BaseNavLink = styled(Link)`
  padding: 6px 12px;
  text-decoration: none;
  color: #000;
  box-shadow: none;
  font-family: 'poppins', sans-serif;
  letter-spacing: 0.135em;
`;

/* 
 * NAVIGATION
 */

export const NavLink = styled(BaseNavLink)`
  font-size: 14px;
  font-weight: 500;

  @media (min-width: ${min.sm}) {
    &::after {
      content: '';
      position: absolute;
      z-index: 2;
      height: 1px;
      width: 0px;
      left: 0;
      right: 0;
      bottom: 0px;
      margin: 0 auto;
      background-color: #000;
      transition: ease all 0.2s;
    }

    &:hover::after, &.active::after {
      width: 55%; 
    }
  }

  @media (max-width: ${max.sm}) {
    width: 100%;
    text-align: center;
  }
`;

export const Ul = styled(BaseUl)`
  @media (max-width: ${max.sm}) {
    display: ${({ mobileNavDisplay }) => mobileNavDisplay || 'none'};
    flex-direction: column;
    background: #c4e3ed;
  }
`;

export const Li = styled(BaseLi)`
  @media (max-width: ${max.sm}) {
    position: unset;
    width: 100%;

    &:hover {
      background: #9dd0e1;
    }
  }
`;

export const NavMenu = styled.nav`
  display: block;
  overflow-y: hidden;
  justify-content: center;

  @media (max-width: ${max.sm}) {
     ${({ mobileDisplay }) => mobileDisplay && css`
      height: 132px; 
      transition: height 0.6s ease-in-out;
    `}

    ${({ mobileDisplay }) => !mobileDisplay && css`
      height: 0px; 
      transition: none;
    `}

    ${({ animateNavClose }) => animateNavClose && css`
      height: 0px; 
      transition: height 0.6s ease-in-out;
    `}
  }

  @media (min-width: ${min.sm}) {
    display: flex;
    height: 60px;
  }
`;

/* 
 * INPAGE NAVIGATION
 */

export const InPageLink = styled(BaseNavLink)`
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;

  &::after {
    content: '';
    position: absolute;
    z-index: 2;
    height: 1px;
    width: 0px;
    left: 0;
    right: 0;
    bottom: 0px;
    margin: 0 auto;
    background-color: #000;
    transition: ease all 0.2s;
  }

  &:hover::after, &.active::after {
    width: 55%; 
  }

  &:visited {
    background-color: black;
  }

  @media (max-width: ${max.xxxs}) {
    font-size: 15px;
  }
`;

export const InPageMenu = styled.nav`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;