import React, { useState, Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Project from '../Project/Project';

import { BaseUl, BaseLi, InPageMenu, InPageLink } from './Navigation.styles';
import { professional, programming } from '../Project/Project.data';

const NavigationInPage = () => {
  const [active, setActive] = useState({
    programming: 'active',
    professional: null,
  });
  const [projects, setProjects] = useState(programming);

  const clicked = e => {
    if (e.target.textContent === 'PROGRAMMING') {
      setActive({ professional: null, programming: 'active' });
      setProjects(programming);
    } else {
      setActive({ professional: 'active', programming: null });
      setProjects(professional);
    }
  };
 
  const allImages = useStaticQuery(graphql`
    query {
      glassBridgeGame: file(relativePath: { eq: "assets/squid-games-glass-bridge-challenge.png" }) {
        childImageSharp {
          gatsbyImageData(width: 850, quality: 45, breakpoints: [540, 750, 850, 1050, 1200, 1500, 1700], sizes: "(max-width: 425px) 300px, 100vw")
        }
      }
      plotGenerator: file(relativePath: { eq: "assets/plot-generator.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 850, quality: 60)
        }
      }
      ticTacToe: file(relativePath: { eq: "assets/tic-tac-toe.png" }) {
        childImageSharp {
          gatsbyImageData(width: 850, quality: 45, breakpoints: [540, 750, 850, 1050, 1200, 1500, 1700], sizes: "(max-width: 425px) 300px, 100vw")
        }
      }
      pollutedCities: file(relativePath: { eq: "assets/most-polluted.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 850, quality: 60)
        }
      }
      facialImageRecognitionApp: file(relativePath: { eq: "assets/facial-recognition.png" }) {
        childImageSharp {
          gatsbyImageData(width: 850, quality: 45, breakpoints: [540, 750, 850, 1050, 1200, 1500, 1700], sizes: "(max-width: 425px) 300px, 100vw")
        }
      }
      fccLocalWeatherApp: file(relativePath: { eq: "assets/weather-app.png" }) {
        childImageSharp {
          gatsbyImageData(width: 850, quality: 80)
        }
      }
      randomQuoteGeneratorMachine: file(relativePath: { eq: "assets/random-quote-machine.png" }) {
        childImageSharp {
          gatsbyImageData(width: 850, quality: 60)
        }
      }
      davidWebsite: file(relativePath: { eq: "assets/david-website.png" }) {
        childImageSharp {
          gatsbyImageData(width: 850, quality: 70)
        }
      }
    }
  `);

  return (
    <Fragment>
      <InPageMenu as="div">
        <BaseUl>
          <BaseLi>
            <InPageLink
              as="span"
              onClick={clicked}
              className={active.programming}
            >
              PROGRAMMING
            </InPageLink>
          </BaseLi>
          <BaseLi>
            <InPageLink
              as="span"
              onClick={clicked}
              className={active.professional}
            >
              PROFESSIONAL
            </InPageLink>
          </BaseLi>
        </BaseUl>
      </InPageMenu>
      {projects.map(project => (
        <Project key={project.projectName} image={allImages[project.projectName].childImageSharp.gatsbyImageData} {...project} />
      ))}
    </Fragment>
  );
};

export default NavigationInPage;
