import React from 'react';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    background: #f4f4f4;
  }
`;


const HomeLayout = props => {
  return (
    <div className={props.className}>
      <GlobalStyles />
      {props.children}
    </div>
  );
};

export default HomeLayout;