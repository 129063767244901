import React, { useState } from 'react';

import LinkWrapper from '../LinkWrapper/LinkWrapper';
import Logo from '../../blog/assets/main/logo';
import Navigation from '../Navigation/Navigation';

import { ROUTES } from '../../routes';

import { HeaderContainer, IconBar, LogoInitial, MainLogo, NavMenu, UpperNavContainer } from './Header.styles';

const Header = () => {
  const [navStyles, setNavStyles] = useState(false);

  const handleMenuToggle = () => {
    setNavStyles(!navStyles);
  };

  return (
  <HeaderContainer>
    <UpperNavContainer>
      <LinkWrapper to={ROUTES.HOME.toUrl()}>
        <MainLogo>
          <Logo />
          <LogoInitial>David</LogoInitial>
        </MainLogo>
      </LinkWrapper>
      <NavMenu 
        ariaExpanded="false" 
        ariaLabel="Toggle navigation"
        dataToggle="collapse"
        onClick={handleMenuToggle}
        type="button"
      >
        <IconBar />
        <IconBar />
        <IconBar />
      </NavMenu>
    </UpperNavContainer>
    <Navigation displayNavMenu={navStyles} toggleMenu={handleMenuToggle} />
  </HeaderContainer>
  )
};


export default Header;
