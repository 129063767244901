import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import BlogCard from './BlogCard/BlogCard';
import SectionHeading from '../../components/SectionHeading/SectionHeading';

import { blogData } from './BlogSection.data';

import { BlogSectionContainer, BlogSectionContent, WaveContainer } from './BlogSection.styles';

const BlogSection = () => {
  const data = useStaticQuery(graphql`
    query {
      investing: file(relativePath: { eq: "assets/investing.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 65, breakpoints: [360, 540, 600, 750, 900, 1200], sizes: "(max-width: 360px) 230px, (max-width: 550px) 350px, (min-width: 1151px) 450px, 100vw") 
        }
      }
      programming: file(relativePath: { eq: "assets/programming.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 65, breakpoints: [450, 600, 750, 900, 1200], sizes: "(min-width: 1151px) 450px, 100vw")
        }
      }
    }
  `);

  return (
    <>
      <BlogSectionContainer>
        <SectionHeading text="My Blog" shapeColor="rgba(23, 176, 1, 0.53)" />
        <BlogSectionContent>
          {blogData.map(({ body, img, stripColor, title, url }) => {
            const id = title.toLowerCase();
            const imgData = { ...img, imgUrl: data[id].childImageSharp.gatsbyImageData };
            return (
              <BlogCard
                key={`blog-section-${id}`}
                body={body}
                title={title}
                stripColor={stripColor}
                imgData={imgData}
                linkUrl={url}
              />
            );
          })}
        </BlogSectionContent>
      </BlogSectionContainer>
      <WaveContainer />
    </>
  );
};

export default BlogSection;
