import styled, { css } from 'styled-components';
import { max } from '../../styles/breakpoints';

export const MainButton = styled.button.attrs(({ ariaLabel }) => ({
  'aria-label': ariaLabel,
}))`
  border: none;
  text-align: center;
  height: fit-content;
  box-sizing: border-box;
  text-decoration: none;
  padding: 10px 25px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 2px;
  align-self: center;

  background-color: ${({ bgColor }) => bgColor || 'black'};
  color: ${({ color }) => color || 'rgb(255, 255, 255)'};
  font-size: ${({ fontSize }) => fontSize || '15px'};
  font-weight: ${({ fontWeight }) => fontWeight || '600'};
  border-radius: ${({ radius }) => radius || '6px'};
  margin: ${({ margin }) => margin};

  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.25);
  }

  ${props => props.social && css`
    margin: ${({ margin }) => margin || '0 5px'};
    padding: unset;
    transition: ease all 0.4s;
    background-color: ${({ bgColor }) => bgColor || '#30819c'};
    width: ${({ size }) => `${size}px` || '32px'};
    height: ${({ size }) => `${size}px` || '32px'};

    &:hover {
      box-shadow: none;
      background-color: ${({ bgColor }) => bgColor || '#02060d'};
      opacity: ${({ hoverOpacity }) => hoverOpacity || '1'};
      transition: ease all 0.6s;
    }
  `};

  ${({ social, resize }) => (social && resize) && css`
    @media (max-width: ${max.md}) {
      width: ${({ size }) => `${size * 0.8}px` };
      height: ${({ size }) => `${size * 0.8}px` };
    }
  `};
`;
