import styled from 'styled-components';

import { max, min } from '../../styles/breakpoints';

export const HeaderContainer = styled.header`
  min-height: 60px;
  display: flex;
  justify-content: center;

  @media (max-width: ${max.sm}) {
    flex-direction: column;
    min-height: 50px;
  }
`;

export const UpperNavContainer = styled.div`
  display: flex;
  margin: 0 20px;
`;

export const IconBar = styled.span`
  display: block;
  margin: 4px 0;
  width: 22px;
  height: 2px;
  background: #000;
  border-radius: 1px;

  &:first-child {
    width: 14px;
  }

  &:last-child {
    width: 18px;
  }
`;

export const NavMenu = styled.button.attrs(({ ariaExpanded, ariaLabel, dataToggle, type }) => ({
  'aria-expanded': ariaExpanded,
  'aria-label': ariaLabel,
  'data-toggle': dataToggle,
  'type': type,
}))`
  display: block;
  height: fit-content;
  align-self: center;
  background: none;
  border: none;
  cursor: pointer;
  margin: 12px 0 12px auto;

  @media (min-width: ${min.sm}) {
    display: none;
    margin: 18px 20px 18px auto;
  }
`;

export const MainLogo = styled.div`
  position: relative;
  width: 90px;
  display: flex;
  justify-content: center;
  height: 50px;

  svg {
    position: absolute;
    top: 3px;
    left: 0;
    width: 45px;
    fill: #44a5c5;
    stroke: #246175;

    stroke-width: 5px;
    stroke-opacity: 0.2;
  }
  @media (min-width: ${min.sm}) {
    display: none;
  }
`;

export const LogoInitial = styled.div`
  position: absolute;
  top: 12px;
  left: 6px;
  width: auto;
  color: #000;
  font-family: Georgia;
  font-size: 30px;
  line-height: 1;
`;