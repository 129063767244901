import styled from 'styled-components';
import { Link } from 'gatsby';

export const ExternalLinkContainer = styled.a.attrs(({ ariaLabel }) => ({
  'aria-label': ariaLabel,
}))`
  display: ${({ display }) => display || 'flex'};
  justify-content: center;
  box-shadow: none;
  color: ${({ color }) => color || 'unset'};
  text-decoration: ${({ decor }) => decor || 'none'};

  svg.fa-lg {
    width: 1em;
    height: 1.3333333333em;
    font-size: 1.3333333333em;
  }

  svg.fa-2x {
    font-size: 2.2em;
  }
`;

export const InternalLinkContainer = styled(Link)`
  display: ${({ display }) => display || 'flex'};
  justify-content: center;
  box-shadow: none;
  color: unset;
  text-decoration: none;
`;