import React from 'react';
import { Link } from 'gatsby';

import Button from '../../components/Button/Button';
import SectionHeading from '../../components/SectionHeading/SectionHeading';

import { ROUTES } from '../../routes';

import {
  AboutContainer,
  AboutContent,
  ButtonContainer,
  Emphasis,
  InlineEmphasis,
  Punchline,
  TextContent,
  TextExcerpt,
  VideoContainer,
  VideoElement,
  VideoSection,
  VideoText,
} from './AboutMe.styles';

const AboutMe = () => (
  <AboutContainer id="about">
    <SectionHeading text="My Story" shapeColor="rgba(162, 17, 17, 0.48)" />
    <AboutContent>
      <TextContent>
        <TextExcerpt>
          <p>
            Many years ago I had the WORST thought come into my mind. It wasn’t that anything
            unusual happened. It was just this thing that was slowly building up inside of me, and
            that I was ignoring. Ignoring it until I couldn’t. I remember it happened several years
            after I had spent 4 years of my life studying to get a piece of paper (also known as a
            degree). I had thought to myself…
          </p>
          <Emphasis>Is this it??? Is this really what I had worked sooo hard to achieve??</Emphasis>
          <p>
            I had let those thoughts sink into my mind for a moment. I realised that I was finally
            opening myself to what I was ignoring for quite some time.
            <InlineEmphasis> I just didn’t want to work in this career anymore!</InlineEmphasis>
          </p>
          <p>
            And then it HIT me. <InlineEmphasis>I… I….I just wasn’t…. happy!!</InlineEmphasis> Yes,
            that was it! I wasn’t happy with my career or life.
          </p>
          <p>
            My worst fear had come true... I had picked the wrong career! It took me a while before
            I started accepting these thoughts. When I finally did, my mind began thinking about
            making a change and if there was a better way forward.
          </p>
          <Emphasis>
            But I was too old! It was too late for me to switch careers. There was no way I could go
            through university again. It’s impossible!
          </Emphasis>
          <p>I let those thoughts sit with me and then I asked myself a better question...</p>
          <Emphasis>Or is it??? …</Emphasis>
        </TextExcerpt>
        <Punchline>Read my full story below!</Punchline>
        <ButtonContainer>
          <Link
            to={ROUTES.BLOG_POST.toUrl('/b/david-nowaks-story-how-it-all-started/#continue')}
            style={{ boxShadow: 'none' }}
          >
            <Button>David Nowak's Story</Button>
          </Link>
        </ButtonContainer>
      </TextContent>
      <VideoSection>
        <VideoContainer>
          <VideoElement controls />
          <VideoText>Coming Soon!</VideoText>
        </VideoContainer>
      </VideoSection>
    </AboutContent>
  </AboutContainer>
);

export default AboutMe;
