import React from 'react';

import { SectionContainer, Shape } from './SectionHeading.styles.js';

const SectionHeading = ({ shapeColor, text }) => (
  <SectionContainer>
    {text}
    <Shape color={shapeColor}/>
  </SectionContainer>
);

export default SectionHeading;
