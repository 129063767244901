import GitHub from '../../blog/components/icons/github';
import SolidGlobe from '../../blog/components/icons/globe-solid';
import YouTube from '../../blog/components/icons/youtube';

import css from './assets/css3.svg';
import express from './assets/express.svg';
import gatsby from './assets/gatsby.svg';
import graphql from './assets/graphql.svg';
import html from './assets/html5.svg';
import javascript from './assets/javascript.svg';
import jquery from './assets/jquery.svg';
import node from './assets/node.svg';
import postgresql from './assets/postgresql.svg';
import react from './assets/react.svg';

export const programming = [
  {
    title: 'Squid Games: Glass Bridge Challenge Game',
    projectName: 'glassBridgeGame',
    icons: [
      { 
        name: 'HTML', 
        icon: html,
      },
      {
        name: 'CSS',
        icon: css,
      },
      {
        name: 'JavaScript',
        icon: javascript,
      },
      {
        name: 'React',
        icon: react,
      },
    ],
    description: {
      intro:
        'Inspired by MrBeast\'s Squid Games YouTube video. I recreated the fun glass bridge challenge game',
      body:
        'This game is seriously addicting and absolutely awesome! Are you able to get all the numbers from one platform to the other one at the end of the bridge? Just make sure you don\'t click on the wrong stepping platform or you\'ll be in for a unpleasant surprise. ',
      conclusion:
        'I build this game as quickly as possible and that is exactly how you are meant to play. Can you get to the other side before time runs out?',
    },
    links: [
      {
        backgroundColor: 'green',
        color: 'white',
        icon: SolidGlobe,
        label: 'Live',
        key: 'live',
        title: 'Live Link to my Squid Games: Glass Bridge Challenge Game',
        url: '/p/squid-game-glass-bridge-challenge/',
      },
      {
        backgroundColor: '#000',
        color: 'white',
        icon: GitHub,
        label: 'Code',
        key: 'github',
        title: 'Project Code for Squid Games: Glass Bridge Challenge game',
        url: 'https://github.com/Confidenceiskey/squid-game-glass-bridge-challenge',
      },
      {
        backgroundColor: 'red',
        color: 'white',
        icon: YouTube,
        label: 'YT',
        key: 'youtube',
        title: 'Watch me build out the UI of this glass bridge challenge game on hyper speed',
        url: 'https://youtu.be/elugaR4pkn8',
      },
    ],
  },
  {
    title: 'Star Wars Plot Generator',
    projectName: 'plotGenerator',
    icons: [
      { 
        name: 'HTML', 
        icon: html,
      },
      {
        name: 'CSS',
        icon: css,
      },
      {
        name: 'JavaScript',
        icon: javascript,
      },
      {
        name: 'React',
        icon: react,
      },
    ],
    description: {
      intro:
        'Are you a Star Wars fan? Curious to find out about some top secret episodes?',
      body:
        'Then this Star Wars Plot Generator will blow your mind. It is a cool, fun little app where you can select what user resources (planets or characters) you want in your story and automatically get a randomly generated plot episode!',
      conclusion:
        'What are you waiting for? Check out this Star Wars Plot Generator for the awesome UI and some seriously funny episode summaries!',
    },
    links: [
      {
        backgroundColor: 'green',
        color: 'white',
        icon: SolidGlobe,
        label: 'Live',
        key: 'live',
        title: 'Live Link to Star Wars Plot Generator App',
        url: '/p/star-wars-plot-generator/',
      },
      {
        backgroundColor: '#000',
        color: 'white',
        icon: GitHub,
        label: 'Code',
        key: 'github',
        title: 'Project Code for Star Wars Plot Generator App',
        url: 'https://github.com/Confidenceiskey/plot-generator',
      },
    ],
  },
  {
    title: 'Tic Tac Toe',
    projectName: 'ticTacToe',
    icons: [
      { 
        name: 'HTML', 
        icon: html,
      },
      {
        name: 'CSS',
        icon: css,
      },
      {
        name: 'JavaScript',
        icon: javascript,
      },
      {
        name: 'React',
        icon: react,
      },
    ],
    description: {
      intro:
        'Do you have what it takes to beat the computer AI in this fun and classical game of tic tac toe?',
      body:
        "Built with a sleek UI, along with great gameplay, you won't want to stop. This game of Tic Tac Toe is guaranteed to entertain and make you smile, especially as you struggle to beat the computer",
      conclusion: 
        'Are you up for the challenge? Then check it out below!',
    },
    links: [
      {
        backgroundColor: 'green',
        color: 'white',
        icon: SolidGlobe,
        label: 'Live',
        key: 'live',
        title: 'Live Link to Tic Tac Toe Game',
        url: '/p/tic-tac-toe/',
      },
      {
        backgroundColor: '#000',
        color: 'white',
        icon: GitHub,
        label: 'Code',
        key: 'github',
        title: 'Project Code for Tic Tac Toe',
        url: 'https://github.com/Confidenceiskey/tic-tac-toe',
      },
    ],
  },
  {
    title: 'Most Polluted Cities',
    projectName: 'pollutedCities',
    icons: [
      { 
        name: 'HTML', 
        icon: html,
      },
      {
        name: 'CSS',
        icon: css,
      },
      {
        name: 'JavaScript',
        icon: javascript,
      },
      {
        name: 'React',
        icon: react,
      },
    ],
    description: {
      intro:
        'Curious to know where the top 10 most polluted cities currently are in your country?',
      body:
        "This app tells you just that for a few, European countries. Within a selected country you'll find out each city's pollution index, which is based off the air quality index (AQI) calculations used in Canada.",
      conclusion:
        "While you're also admiring the cool UI design, you might just be surprised by the results.",
    },
    links: [
      {
        backgroundColor: 'green',
        color: 'white',
        icon: SolidGlobe,
        label: 'Live',
        key: 'live',
        title: 'Live Link to Most Polluted Cities App',
        url: '/p/most-polluted-cities/',
      },
      {
        backgroundColor: '#000',
        color: 'white',
        icon: GitHub,
        label: 'Code',
        key: 'github',
        title: 'Project Code for Most Polluted Cities App',
        url: 'https://github.com/Confidenceiskey/most-polluted-cities',
      },
    ],
  },
  {
    title: 'Facial Image Recognition App',
    projectName: 'facialImageRecognitionApp',
    icons: [
      { 
        name: 'HTML', 
        icon: html,
      },
      {
        name: 'CSS',
        icon: css,
      },
      {
        name: 'JavaScript',
        icon: javascript,
      },
      {
        name: 'React',
        icon: react,
      },
      {
        name: 'Node.js',
        icon: node,
      },
      {
        name: 'Express.js',
        icon: express,
      },
      {
        name: 'PostgreSQL',
        icon: postgresql,
      },
    ],
    description: {
      intro:
        'Want to see the power of what AI can do? Then check out this fun, little facial recognition app.',
      body:
        'It lets you upload any photo, and uses the Clarifai API to detect whether any faces are present. If a face does exist, it will draw a coloured box around it. In addition, this app includes authentication (login/logout capabilities), a backend server created in Node, and stores the data in a PostgreSQL database.',
      conclusion:
        'Do you think you can trick the AI? Check out the link below, register and try uploading a photo with a face on it. Will the AI detect it?',
    },
    links: [
      {
        backgroundColor: '#000',
        color: 'white',
        icon: GitHub,
        label: 'Code',
        key: 'github',
        title: 'Project Code for Facial Image Recognition App',
        url: 'https://github.com/Confidenceiskey/face-recognition-brain',
      },
    ],
  },
  {
    title: 'FCC Local Weather App',
    projectName: 'fccLocalWeatherApp',
    icons: [
      { 
        name: 'HTML', 
        icon: html,
      },
      {
        name: 'CSS',
        icon: css,
      },
      {
        name: 'JavaScript',
        icon: javascript,
      },
      {
        name: 'JQuery',
        icon: jquery,
      },
    ],
    description: {
      intro:
        'Curious to know what the weather is like in your local area?',
      body:
        'This small weather app uses an open API to retrieve the current weather in your location. This is accomplished by using your geolocation coordinates. Depending on the temperature in your area, the background colour will change much in the same way as a heat map. Now I\'m sure you want to know what the weather is like in your area!',
      conclusion:
        'Click below and find out! And don\'t forget to allow location access, or else the app will not work properly!',
    },
    links: [
      {
        backgroundColor: 'green',
        color: 'white',
        icon: SolidGlobe,
        label: 'Live',
        key: 'live',
        title: 'Live Link to FCC Local Weather App',
        url: '/p/fcc-weather-app/',
      },
      {
        backgroundColor: '#000',
        color: 'white',
        icon: GitHub,
        label: 'Code',
        key: 'github',
        title: 'Project Code for FCC Local Weather App',
        url: 'https://github.com/Confidenceiskey/dpn-site/tree/gh-pages/p/fcc-weather-app',
      },
    ],
  },
  {
    title: 'FCC Random Quote Generator Machine',
    projectName: 'randomQuoteGeneratorMachine',
    icons: [
      { 
        name: 'HTML', 
        icon: html,
      },
      {
        name: 'CSS',
        icon: css,
      },
      {
        name: 'JavaScript',
        icon: javascript,
      },
      {
        name: 'JQuery',
        icon: jquery,
      },
    ],
    description: {
      intro:
        'Are you in need of some inspirational quotes? Then look no further then this app!',
      body:
        'This random quote generator machine will provide you with an awesome and motivational quote. What... you don\'t like it? Well, no worries! You can keep refreshing until you come across that quote that you really like. And when you do find it, you may as well press the twitter button and just tweet it to all of your friends!',
      conclusion:
        'Now go and find a new inspirational, or motivational, quote in the link below!',
    },
    links: [
      {
        backgroundColor: 'green',
        color: 'white',
        icon: SolidGlobe,
        label: 'Live',
        key: 'live',
        title: 'Live Link to FCC Random Quote Generator Machine',
        url: '/p/random-quote-generator-machine/',
      },
      {
        backgroundColor: '#000',
        color: 'white',
        icon: GitHub,
        label: 'Code',
        key: 'github',
        title: 'Project Code for FCC Random Quote Generator Machine',
        url: 'https://github.com/Confidenceiskey/dpn-site/tree/gh-pages/p/random-quote-generator-machine',
      },
    ],
  },
];

export const professional = [
  {
    title: 'David Nowak\'s Website',
    projectName: 'davidWebsite',
    icons: [
      { 
        name: 'HTML', 
        icon: html,
      },
      {
        name: 'CSS',
        icon: css,
      },
      {
        name: 'JavaScript',
        icon: javascript,
      },
      {
        name: 'React',
        icon: react,
      },
      {
        name: 'Gatsby',
        icon: gatsby,
      },
      {
        name: 'GraphQL',
        icon: graphql,
      }
    ],
    description: {
      intro:
        'I was in need of making a new, more professional website that still had a programming core to it, but also aligned with some new personal goals of mine and would help keep me accountable. I also wanted my new site to be fast and have blogging capabilities.',
      body:
        'After figuring out my requirements and doing some research, I ended up deciding on building a JAMstack website using Gatsby. This option met all of my criteria, was exactly what I was looking for and gave me full control over my website. This was a great decision for me as a developer. In addition, it also allowed me to learn GraphQL, which was something that I wanted to add to my tech skills. Win, win!',
      conclusion:
        'In the end, I was very happy with how my site turned out. It works really well and is super fast!',
    },
    links: [
      {
        backgroundColor: 'green',
        color: 'white',
        icon: SolidGlobe,
        label: 'Live',
        key: 'live',
        title: 'Live Link to David Nowak\'s Website & Blog',
        url: '/',
      },
    ],
  },
];
