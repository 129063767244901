import styled from 'styled-components';

import { max, min } from '../../styles/breakpoints';

/* POSSIBLE BACKGROUND COLORS
 * #2E5866 (dark background)
 * #638C99 (dark-and slighly lighter background - grey) ****
 * #E6E095 (yellowish)
 * #e0f0f5 (very light shade of jumbotron version)
 */

export const AboutContainer = styled.section`
  background-color: #e0f0f5;
  padding: 60px 0 90px;

@media (min-width: ${min.xs}) and (max-width: ${max.lg}) {
  padding: 60px;
}

@media (min-width: ${min.lg}) {
  padding: 60px 120px;
}
`;

export const AboutContent = styled.div`
  display: flex;
  margin-top: 50px;


  @media (max-width: ${max.xxl}) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const VideoSection = styled.div`
  display: flex;
  width: 46%;
  min-width: 450px;
  align-items: center;

  @media (max-width: ${max.xxl}) {
    width: 100%;
    max-width: 550px;
    margin-bottom: 25px;
  }

  @media (max-width: ${max.xs}) {
    min-width: unset;
  }
`;

export const VideoContainer = styled.div`
  width: 100%;
  padding-top: 56.25%;
  height: 0;
  position: relative;
`;

export const VideoElement = styled.video`
  background-color: gray;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const VideoText = styled.div`
  position: absolute;
  opacity: 0;
  top: 40%;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  font-size: 40px;
  transition: ease opacity 0.5s;

  ${VideoContainer}:hover &,
  ${VideoContainer}:active & {
    opacity: 1;
    transition: ease opacity 1s;
  }
`;

export const TextContent = styled.div`
  width: 54%;
  display: flex;
  flex-direction: column;
  padding-right: 80px;

  @media (min-width: ${min.xs}) and (max-width: ${max.xxl}) {
    width: 100%;
    padding-right: 0;
  }

  @media (max-width: ${max.xs}) {
    width: 100%;
    box-sizing: border-box;
    padding: 0 30px;
  }
`;

export const TextExcerpt = styled.div`
  font-family: Georgia, serif;
  line-height: 1.45;
`;

export const Emphasis = styled.p`
  font-style: italic;
  font-family: poppins, sans-serif;
  font-size: 0.95em;
`;

export const InlineEmphasis = styled.span`
  font-style: italic;
  font-family: poppins, sans-serif;
  font-size: 0.95em;
`;

export const Punchline = styled.p`
  font-size: 22px;
  margin-bottom: 20px;

  @media (max-width: ${max.xxl}) {
    text-align: center;
  }
`;

export const ButtonContainer = styled.div`
  @media (max-width: ${max.xxl}) {
    text-align: center;
  }
`;
