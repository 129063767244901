import styled from 'styled-components';

import { max, min } from '../../styles/breakpoints';

const TRANSITION_IN = 0.5;
const TRANSITION_OUT = 0.6;

export const ProjectContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 60px 0 120px;

  &:last-child {
    margin-bottom: 60px;
  }

  @media (max-width: ${max.sm}) {
    margin-bottom: 75px;

    &:last-child {
      margin-bottom: 30px;
    }
  }
`;

export const ImgContainer = styled.div`
  position: relative;
  width: 850px;
  height: auto;
  overflow: hidden;
`;

export const Overlay = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: ease background-color ${TRANSITION_OUT}s;

  ${ImgContainer}:hover &,
  ${ImgContainer}:active & {
    background-color: rgba(0, 0, 0, 0.7);
    transition: ease background-color ${TRANSITION_IN}s;
  }
`;

export const CaptionContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: #fff;
  min-width: 320px;
  bottom: 0;
  left: -56%;
  height: 100%;
  width: 55%;
  transition: ease all ${TRANSITION_OUT}s;

  @media (max-width: ${max.xs}) {
    width: 75%;
    left: -100%;
  }

  @media (min-width: ${min.xs}) and (max-width: ${max.lg}) {
    width: 65%;
    left: -70%;
  }

  @media (max-width: ${max.lg}) {
    transition: ease left ${TRANSITION_OUT + 0.35}s;
  }

  ${ImgContainer}:hover &,
  ${ImgContainer}:active & {
    left: 0;
    transition: ease left ${TRANSITION_IN + 0.35}s;
  }
`;

export const CaptionBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: 0 25px;
  overflow-y: scroll;
  height: calc(100% - 40px);
  width: auto;

  @media (max-width: ${max.md}) {
    padding: 0 15px;
  }
`;

export const ProjectTitle = styled.h3`
  font-family: 'poppins', sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin: 0;

  @media (max-width: ${max.sm}) {
    font-size: 1.25rem;
  }
`;

export const Text = styled.p`
  font-family: Georgia, serif;
  line-height: 1.35;
  margin-top: 0;
`;

export const TechIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 15px 0 0;
`;

export const TechIcon = styled.div`
  margin: 0 5px 25px;
  height: 45px;
  width: 45px;
  background: url(${({ icon }) => icon }) no-repeat;

  @media (max-width: ${max.sm}) {
    height: 34px;
    width: 34px;
    margin-bottom: 20px;
  }
`;

export const ProjectDescription = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  @media (max-width: ${max.sm}) {
    font-size: 15px;
  }
`;

export const ProjectWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #fafafa;
  height: inherit;
  border-left: 1px solid #e6e6e6;
`;

export const ProjectBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-width: 50px;
  max-width: 50px;
  padding: 10% 0;
  height: 80%;

  @media (max-width: ${max.md}) {
    min-width: 40px;
    max-width: 40px;
    height: 100%;
    padding: 0;
  }

  @media (max-width: ${max.xxs}) {
    justify-content: center;
  }

  @media (min-width: ${min.xxs}) and (max-width: ${max.md}) {
    justify-content: space-evenly;
  }
`;

export const ProjectLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 0;
`;

export const ProjectText = styled(Text)`
  margin: 0;
  @media (max-width: ${max.sm}) {
    font-size: 0.85rem;
  }
`;

export const ImgCaption = styled.div`
  display: flex;
  width: 100%;
  height: 42px;
  background: #e6e6e6;
  background: #e4f5fb;
  font-family: Georgia, serif;
  font-size: 2rem;
  justify-content: center;
  align-items: center;

  @media (max-width: ${max.xxs}) {
    height: 25px;
    font-size: 1.15rem;
  }

  @media (min-width: ${min.xxs}) and (max-width: ${max.md}) {
    height: 32px;
    font-size: 1.5rem;
  }

  @media (min-width: ${min.xl}) {
    display: none;
  }
`;
