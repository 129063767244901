import React from 'react';
import styled, { css } from 'styled-components';
import { max } from '../../../../styles/breakpoints';

export const withProps = (Component) => (props) => {
  return (
    <Component {...props} />
  );
}

export const withStyles = (Component) => styled(Component).attrs(({ color, size }) => ({
  fill: color || '#fff',
  height: size ? `${size}em` : '1.55em',
}))`
  margin: ${({ margin }) => margin || null};

  ${({ resize }) => resize && css`
    @media (max-width: ${max.md}) {
      height: ${({ size }) => `${size * 0.8}em` || '1.28em'};
    }
  `};
`;
