export const max = {
  xxxs: '367px',
  xxs: '467px',
  xs: '567px',
  sm: '667px',
  md: '767px',
  lg: '967px',
  xl: '1150px',
  xxl: '1350px',
};

export const min = {
  xxxs: '368px',
  xxs: '468px',
  xs: '568px',
  sm: '668px',
  md: '768px',
  lg: '968px',
  xl: '1151px',
  xxl: '1351px',
};
