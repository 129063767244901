import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

import Envelope from '../../blog/components/icons/envelope';

import LinkWrapper from '../../components/LinkWrapper/LinkWrapper';
import SectionHeading from '../../components/SectionHeading/SectionHeading';

import {
  BodyContainer,
  ContactAddress,
  ContactContainer,
  ContactMethodContainer,
  ImgContainer,
  TextContainer,
  Text,
} from './Contact.styles';

const Contact = () => {
  const photo = useStaticQuery(graphql`
    query {
      me: file(relativePath: { eq: "assets/david-contact.png" }) {
        childImageSharp {
          gatsbyImageData(width: 300, height: 328, layout: FIXED, quality: 70)
        }
      }
    }
  `);


  return (
    <ContactContainer id="contact">
      <SectionHeading text="Contact Me!" shapeColor="rgba(162, 17, 17, 0.48)" />
      <BodyContainer>
        <ImgContainer>
          <GatsbyImage
            image={photo.me.childImageSharp.gatsbyImageData}
            alt="Portrait of David Nowak. A Frontend Developer and Investor"
          />
        </ImgContainer>
          <TextContainer>
            <Text> 
              Hey! Did you enjoy something on my website? Maybe you liked 
              a piece of content that I wrote? Or maybe you just have similar ambitions 
              to me. If you are especially thinking about…
            </Text>
            <Text>
              &#8226; Switching careers into tech, or wanting to get your first developer job
              <br/>
              &#8226; Want to learn more about investing, or are interested in joining a high 
              performance trading group
              <br/>
              &#8226; Have a desire to achieve financial independence, or be location independent
            </Text>
            <Text>OR also just if you...</Text>
            <Text>
              &#8226; Enjoy traveling and want to build a network of friends from around 
              the world
              <br/>
              &#8226; Have business aspirations or endeavours 
            </Text>
            <Text>Then I’d love to hear from you!</Text>
          </TextContainer>
          <ContactMethodContainer>
            <LinkWrapper to='mailto:hello[at]davidpnowak.com' ariaLabel='Click to email David Nowak'>
              <Envelope color='#18414e' size={3} />
            </LinkWrapper>
            <ContactAddress>hello[at]davidpnowak.com</ContactAddress>
          </ContactMethodContainer>
      </BodyContainer>
    </ContactContainer>
  );
};

export default Contact;
