import styled from 'styled-components';

import backgroundImage from '../../assets/contact-wave.svg';
import { max, min } from '../../styles/breakpoints';

export const BlogSectionContainer = styled.section`
  background-color: #e0f0f5;
  padding: 60px 0 90px;

  @media (min-width: ${min.xs}) and (max-width: ${max.md}) {
    padding: 60px;
  }

  @media (min-width: ${min.md}) {
    padding: 60px 120px;
  }
`;

export const BlogSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  @media (min-width: ${min.xl}) {
    flex-direction: row;
    justify-content: center;
    align-items: unset;
  }
`;

export const WaveContainer = styled.div`
  position: absolute;
  height: 367.75px;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    background: url(${backgroundImage}) no-repeat;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1
  }
`;
