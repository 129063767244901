import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import Button from '../Button/Button';
import LinkWrapper from '../LinkWrapper/LinkWrapper';

import {
  CaptionBox,
  CaptionContainer,
  ImgCaption,
  ImgContainer,
  Overlay,
  ProjectBox,
  ProjectContainer,
  ProjectDescription,
  ProjectLinkWrapper,
  ProjectText,
  ProjectTitle,
  ProjectWrapper,
  TechIcon,
  TechIconsContainer,
  Text,
} from './Project.styles';

const Project = props => {
  const { description, icons, image, links, projectName, title } = props;

  return (
    <ProjectContainer>
      <ImgContainer>
        <GatsbyImage image={image} alt={title} />
        <Overlay>
          <CaptionContainer>
            <CaptionBox>
              <ProjectTitle>{title}</ProjectTitle>
              <TechIconsContainer>
                {icons.map(({ icon, name }) => (
                  <TechIcon
                    icon={icon}
                    key={`${projectName}-${name}`}
                    alt={name}
                    title={name}
                  />
                ))}
              </TechIconsContainer>
              <ProjectDescription>
                <Text>{description.intro}</Text>
                <Text>{description.body}</Text>
                <Text>{description.conclusion}</Text>
              </ProjectDescription>
            </CaptionBox>
            <ProjectWrapper>
              <ProjectBox>
                {links.map(link => (
                  <ProjectLinkWrapper key={`${projectName}-${link.key}`}>
                    <Button
                      social
                      resize
                      bgColor={link.backgroundColor}
                      hoverOpacity="0.6"
                      margin="0 0 3px"
                      size={37.5}
                      title={link.title}
                    >
                      <LinkWrapper
                        ariaLabel={link.title}
                        to={link.url}
                        type={link.key}
                      >
                        <link.icon
                          resize="true"
                          fill={link.color}
                          size={1.66}
                        />
                      </LinkWrapper>
                    </Button>
                    <ProjectText>{link.label}</ProjectText>
                  </ProjectLinkWrapper>
                ))}
              </ProjectBox>
            </ProjectWrapper>
          </CaptionContainer>
        </Overlay>
        <ImgCaption>FULL PROJECT DESCRIPTION</ImgCaption>
      </ImgContainer>
    </ProjectContainer>
  );
};

export default Project;
