import React, { Component } from 'react';

import AboutMe from '../containers/AboutMe/AboutMe';
import BlogSection from '../containers/BlogSection/BlogSection';
import CalloutBanner from '../containers/CalloutBanner/CalloutBanner';
import Contact from '../containers/Contact/Contact';
import Footer from '../components/Footer/Footer';
import Jumbotron from '../containers/Jumbotron/Jumbotron';
import MainLayout from '../containers/MainLayout/MainLayout';
import MetaData from '../containers/MetaData';
import Portfolio from '../containers/Portfolio/Portfolio';

import { home } from '../website-config';

import HomeLayout from '../layouts/HomeLayout';

class IndexPage extends Component {
  render() {

    return (
      <HomeLayout>
      <MetaData location={home} robots="index, follow" />
        <MainLayout>
          <Jumbotron />
          <AboutMe />
          <CalloutBanner />
          <Portfolio />
          <BlogSection />
          <Contact />
          <Footer />
        </MainLayout>
      </HomeLayout>
    )
  }
}

export default IndexPage;
