import styled from 'styled-components';

import Header from '../../components/Header/Header';

import { max, min } from '../../styles/breakpoints';

export const JumboContainer = styled.div`
  width: 100vw;
  max-width: inherit;
  height: contain;
  min-height: 500px;
  max-height: 100vh;
  background: lightblue;
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: auto 1fr 1fr auto;
  grid-template-areas: 
    "photo nav"
    "photo heading"
    "photo subheading"
    "photo social";

  @media (max-width: ${max.lg}) {
    max-height: unset;
    grid: auto / auto 1fr 1fr 1fr auto;
    grid-template-areas: 
    "nav"
    "heading"
    "photo"
    "subheading"
    "social"
  }
`;

export const ImgContainer = styled.section`
  grid-area: photo;
  overflow-y: hidden;
  text-align: center;

  @media (max-width: ${max.lg}) {
    & .gatsby-image-wrapper {
      max-width: 500px;
    }
  }

  @media (max-width: ${max.xs}) {
    width: 100vw;
    max-height: 525px;
  }
`;

export const NavHeader = styled(Header)`
  grid-area: nav;
`;

export const HeadingContainer = styled.div`
  grid-area: heading;
  padding: 10px 75px 0;
  align-self: end;

  @media (min-width: ${min.sm}) and (max-width: ${max.md}) {
    padding: 10px 60px 0;
  }

  @media (max-width: ${max.sm}) {
    padding: 30px 30px 0;
  }
`;

export const SubHeading = styled.p`
  font-family: 'poppins', sans-serif;
  font-size: 18px;
  margin-bottom: 19px;
  line-height: 28px;

  @media (min-width: ${min.lg}) and (max-width: ${max.xl}) {
    font-size: 17px;
  }
`;

export const SubheadingContainer = styled.div`
  grid-area: subheading;
  padding: 0 75px 10px;

  & ${SubHeading}:last-child {
    color: #005159;
    font-size: 16px;
    font-style: italic;
  }

  @media (min-width: ${min.sm}) and (max-width: ${max.md}) {
    padding: 10px 60px 0;
  }

  @media (max-width: ${max.sm}) {
    padding: 10px 30px 0;
  }
`;

export const SocialContainer = styled.div`
  grid-area: social;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: ${max.lg}) {
    margin-bottom: 40px;
  }
`;

export const GreetingText = styled.p`
  margin: 0;
  font-family: 'poppins', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #005159;
`;

export const NameHeading = styled.h1`
  font-size: 64px;
  font-family: 'poppins', sans-serif;
  font-weight: 600; 
  margin: 10px 0 25px;
  color: #02060d;
  letter-spacing: 0px;

  @media (min-width: ${min.xs}) and (max-width: ${max.xl}) {
    font-size: 48px;
  }

  @media (max-width: ${max.xs}) {
    font-size: 36px;
  }
`;
