import styled from 'styled-components';

import { min, max } from '../../styles/breakpoints';

export const ContactContainer = styled.section`
  position: relative;
  background: lightblue;
  background: -webkit-linear-gradient(to right, lightblue, #76bdd5);
  background: linear-gradient(to right, lightblue, #76bdd5);
  padding: 60px 0 90px;

  @media (min-width: ${min.xs}) and (max-width: ${max.md}) {
    padding: 60px;
  }

  @media (min-width: ${min.md}) {
    padding: 200px 120px 90px;
  }
`;

export const BodyContainer = styled.div`
  display: grid;
  grid-template-columns: 318px 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    "image text"
    "image email";
  margin-top: 50px;
  column-gap: 75px;

  @media (max-width: ${max.sm}) {
    grid-template: 376px auto auto / 1fr;
    grid-template-areas:
      "image"
      "text"
      "email";
    padding: 0 25px;
    justify-items: center;
  }

  @media (min-width: ${min.sm}) and (max-width: ${max.lg} ) {
    grid-template: auto auto auto / 1fr;
    grid-template-areas:
    "text"
    "image"
    "email";
    justify-items: center;
  }

  @media (min-width: ${min.xl}) {
    margin: 50px auto 0;
    column-gap: 125px;
    width: 90%;
  }
`;

export const ImgContainer = styled.div`
  background-color: #fff;
  padding: 8px 8px 40px;
  border: 1px solid #c3cdd5;
  box-sizing: border-box;
  grid-area: image;
  width: 318px;
  height: 376px;
  z-index: 2;

  @media (min-width: ${min.xxxs}) and (max-width: ${max.lg}) {
    margin-top: 25px;
  }

  @media (max-width: ${max.xxxs}) {
    width: 100%;
  }
`;

export const TextContainer = styled.div`
  grid-area: text;
  height: 100%;
  z-index: 2;

  @media (max-width: ${max.sm}) {
    padding-left: 0;
    margin-top: 40px;
  }
`;

export const Text = styled.p`
  font-family: Georgia, serif;
  font-size: 1rem;
  color: #005159;
  line-height: 1.5;
  margin-top: 0;
`;

export const ContactMethodContainer = styled.div`
  grid-area: email;
  display: flex;
  align-items: center;

  @media (max-width: ${max.sm}) {
    margin-top: 34px;
    justify-content: center;
  }

  @media (min-width: ${min.sm}) and (max-width: ${max.lg}) {
    margin-top: 64px;
    justify-content: center;
  }
`;

export const ContactAddress = styled.span`
  padding-left: 40px;
  font-size: 1.375rem;
  font-family: Georgia, serif;

  @media (min-width: ${min.xxxs}) and (max-width: ${max.md}) {
    font-size: 1.2rem;
  }

  @media (max-width: ${max.xxxs}) {
    padding-left: 15px;
    font-size: 1.1rem;
  }
`;