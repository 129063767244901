import React from 'react';

import Button from '../../components/Button/Button';
import LinkWrapper from '../../components/LinkWrapper/LinkWrapper';
import Pen from '../../blog/components/icons/pen';
import YouTube from '../../blog/components/icons/youtube';

import { ROUTES } from '../../routes';

import {
  Banner,
  Caption,
  ContentContainer,
  FlexContainer,
} from './CalloutBanner.styles';

const CalloutBanner = () => (
  <Banner>
    <ContentContainer>
      <FlexContainer>
        <Caption>
          Watch me try to build a SaaS business from scratch in 1 year {'—>'}
        </Caption>
        <Button bgColor="#e60000" margin="0 0 15px 0">
          <LinkWrapper
            to="https://www.youtube.com/channel/UCuFfnF3p1AWLTyZDxegOPzg"
            ariaLabel="David Nowak's YouTube Channel"
          >
            <YouTube size={1.01} margin='auto 10px auto 0' />
            Youtube
          </LinkWrapper>
        </Button>
      </FlexContainer>
      <FlexContainer>
        <Caption>Need some help to get your first developer job? See how I did it here!</Caption>
        <Button bgColor="#1919e6" margin="0 0 15px 0">
          <LinkWrapper to={ROUTES.BLOG_POST.toUrl('/b/how-i-got-my-first-developer-job/')} ariaLabel="An article about how David Nowak got his first developer job">
            <Pen size={1.01} margin='auto 10px auto 0'/>
            Post
          </LinkWrapper>
        </Button>
      </FlexContainer>
    </ContentContainer>
  </Banner>
);

export default CalloutBanner;
