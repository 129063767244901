import styled from 'styled-components';
import { max, min } from '../../styles/breakpoints';

const size = 50;
const sizeXS = 36;

export const Shape = styled.div`
  position: absolute;
  left: -${size/2.5}px;
  top: -${size * 0.2 / 2}px;
  background-color: ${({ color }) => color };
  height: ${size * 1.2}px;
  width: ${size * 1.2}px;
  border-radius: 50%;
  z-index: -1;

  @media (max-width: ${max.xs}) {
    height: ${sizeXS * 1.2}px;
    width: ${sizeXS * 1.2}px;
    left: -${sizeXS/2.5}px;
    top: -${sizeXS * 0.2 / 2}px;
  }
`;

/* Alternative color for About Section
 * color: #c3d3d5; (slighly off white) - if dark background
 * color: #091617: (dark color) - if slightly lighter than dark background  *****
 */

export const SectionContainer = styled.h2`
  position: relative;
  margin: 0;
  font-family: 'poppins', sans-serif;
  font-weight: 500;
  letter-spacing: -2px;
  font-size: ${size}px;
  line-height: ${size}px;
  color: #000;
  z-index: 1;

  @media (max-width: ${max.xs}) {
    font-size: ${sizeXS}px;
    line-height: ${sizeXS}px;
  }

  @media (max-width: ${max.xxxs}) {
    margin-left: 30px;
  }

  @media (min-width: ${min.xxxs}) and (max-width: ${max.xs}) {
    margin-left: 50px;
  }
`;