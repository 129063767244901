import React from 'react';

import { ExternalLinkContainer, InternalLinkContainer } from './LinkWrapper.styles';

const LinkWrapper = ({ ariaLabel, color, children, display, decor, referral, to, type }) => {
  const isWithinSiteLink = type === 'live';
  const target = isWithinSiteLink ? '_self' : '_blank';
  const rel = referral ? 'noopener' : 'noopener noreferrer';
  const isExternalLink = to.includes('http') || to.startsWith('mailto:');

  return isExternalLink || isWithinSiteLink ? (
    <ExternalLinkContainer
      ariaLabel={ariaLabel}
      color={color}
      display={display}
      decor={decor}
      href={to}
      target={target}
      rel={isWithinSiteLink ? null : rel}
    >
      {children}
    </ExternalLinkContainer>
  ) : (
    <InternalLinkContainer aria-label={ariaLabel} display={display} to={to}>
      {children}
    </InternalLinkContainer>
  );
};

export default LinkWrapper;
