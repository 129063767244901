import React, { useState } from 'react';

import { ROUTES } from '../../routes';

import { Li, NavMenu, NavLink, Ul } from './Navigation.styles';

const Navigation = ({ displayNavMenu, toggleMenu }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNavEnd = () => setIsNavOpen(!isNavOpen);

  const showNav = () => displayNavMenu || (!displayNavMenu && isNavOpen) ? 'flex' : 'none';

  return (
    <NavMenu 
      mobileDisplay={displayNavMenu} 
      onTransitionEnd={toggleNavEnd} 
      animateNavClose={!displayNavMenu && isNavOpen}
    >
      <Ul mobileNavDisplay={showNav}>
        <Li onClick={toggleMenu}>
          <NavLink as="a" href="#portfolio">
            PORTFOLIO
          </NavLink>
        </Li>
        <Li onClick={toggleMenu}>
          <NavLink to={ROUTES.ABOUT.toUrl()}>
            ABOUT ME
          </NavLink>
        </Li>
        <Li onClick={toggleMenu}>
          <NavLink to={ROUTES.BLOG.toUrl()}>BLOG</NavLink>
        </Li>
        <Li onClick={toggleMenu}>
          <NavLink as="a" href="#contact">
            CONTACT
          </NavLink>
        </Li>
      </Ul>
    </NavMenu>
  );
}


export default Navigation;
